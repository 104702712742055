import { TimelineLite } from "gsap";
import { Snif } from "../helpers/snif.js";

const bodyScrollLock = require("body-scroll-lock");
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

let menuState = false;
const manageMenu = new TimelineLite();
const closeManageMenu = new TimelineLite();

const header = document.getElementsByClassName("c-header")[0];
const headerLinks = header.getElementsByTagName("a");
const hamIcn = document.getElementsByClassName("c-hamMenu");
const topMenu = document.getElementsByClassName("c-menu")[0];
const categoryMiddle = topMenu.getElementsByClassName("c-menu__middle");
const pageContainer = document.getElementsByClassName("l-page");
const pageContent = document.getElementsByClassName("l-pageContent");
const radialArc = document.getElementsByClassName("c-radial");
const overlay = document.getElementsByClassName("l-page__overlay");
const theBody = document.getElementsByTagName("body");

const body = document.body;

const openMenuTL = () => {
    header.classList.add("-menuOpen");
    manageMenu
        .set(pageContainer, { className: "+=l-page--menuVisible" })
        //.set(header, { className: '+=-menuOpen' })
        .set(radialArc, { className: "+=-menuOpen" })
        .to(topMenu, 0.6, { y: "0%" }, "+=.4")
        .staggerTo(
            ".c-menu__solution",
            0.2,
            {
                className: "+=c-menu__middle--visible"
            },
            0.1
        );
    //.set(body, { overflow: "hidden" });

    disableBodyScroll(body);

    menuState = true;
};

export const closeMenuTL = () => {
    if (!menuState) return;
    header.classList.remove("-menuOpen");
    closeManageMenu
        .staggerTo(
            ".c-menu__solution",
            0.2,
            {
                className: "-=c-menu__middle--visible"
            },
            0.1
        )
        .to(topMenu, 0.5, { y: "-100%" }, "-=.5")
        //.set(header, { className: '-=-menuOpen' })
        .set(radialArc, { className: "-=-menuOpen" })
        .set(pageContainer, { className: "-=l-page--menuVisible" }, "-=.3");
    //.set(body, { overflow: "auto" });

    enableBodyScroll(body);

    menuState = false;
};

const toogleMenu = () => {
    hamIcn[0].addEventListener(
        "click",
        function(event) {
            event.preventDefault();
            if (manageMenu.isActive() || closeManageMenu.isActive()) {
                return;
            }
            if (menuState) {
                // closing menu
                closeMenuTL();
            } else {
                openMenuTL();
            }
        },
        false
    );

    overlay[0].addEventListener(
        "click",
        function(event) {
            event.preventDefault();
            if (manageMenu.isActive() || closeManageMenu.isActive()) {
                return;
            }
            if (menuState) {
                // closing menu
                closeMenuTL();
            }
        },
        false
    );

    if (Snif.isIE11) {
        for (let index = 0; index < headerLinks.length; index++) {
            const elementLink = headerLinks[index];
            const hrefAttr = elementLink.getAttribute("href");
            if (!hrefAttr) return;
            elementLink.addEventListener("click", function name(e) {
                location.href = hrefAttr;
            });
        }
    }
};
const initHamburger = {
    toogleMenu: toogleMenu
};

export default initHamburger;
