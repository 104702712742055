// Import Highway
import Highway from "@dogstudio/highway";
import manageRadial from "../components/radial.js";
import scroll from "../modules/utils";

class ManifestRenderer extends Highway.Renderer {
    // Hooks/methods
    onEnter() {
        manageRadial.radialGradient("manifest", "cyan");

        scroll.ElementsInDOMIntersectionObserver();
        scroll.detectElementsInDOM();
        window.addEventListener("scroll", scroll.detectElementsInDOM);
    }
    onLeave() {}
    onEnterCompleted() {}
    onLeaveCompleted() {}
}

// Don`t forget to export your renderer
export default ManifestRenderer;
