import PagesManager from "../pages/core";
import { TweenLite, TweenMax } from "gsap";

let playIntro = false;

export const PlayIntro = () => {
    const introTL = new TimelineMax({ paused: true });
    const pagesManager = new PagesManager();
    if (navigator.appVersion.indexOf("Trident/") > 0) {
        document.body.classList.add("is-ie11");
    } else {
        document.body.classList.add("is-not-ie11");
    }
    if (navigator.appVersion.indexOf("Trident/") > 0) {
        pagesManager.start();
        return;
    }

    const container = document.getElementsByClassName("c-introLayer")[0];
    const Logo = container.getElementsByTagName("img")[0];
    // const svg = container.getElementsByTagName('svg')[0];
    // const svgPath = svg.getElementsByTagName('path');
    // const circle = document.getElementById('circle-path');
    // const middleGroup = document.getElementById('middle-group');
    // const middleGroupPath = middleGroup.getElementsByTagName('path');
    const radial = document.getElementsByClassName("c-radial")[0];
    const radialSvg = radial.getElementsByTagName("svg")[0];
    const linearGradient = radial.querySelectorAll("stop");

    const pageElems = document.querySelectorAll(
        ".c-offCanvas, .l-mainContainer, .c-header, .c-menuAside, .c-menuAside__extraLabel, .c-footer"
    );

    const stopColorNeutral = [
        "rgba(167, 234, 253, .6)",
        "rgba(100, 202, 227, .8)",
        "rgba(0, 161, 195, 1)"
    ];

    introTL
        // .set(document.body, {
        //     overflow: 'hidden'
        // })
        .staggerTo(
            linearGradient,
            1,
            {
                stopColor: "#A7EAFD",
                cycle: {
                    stopColor: stopColorNeutral
                }
            },
            0.1
        )
        .set(radial, {
            zIndex: 99,
            scale: 10
        })
        .to(radial, 0.3, {
            alpha: 1
        })
        .to(radial, 2, {
            scale: 1
        })
        .to(
            Logo,
            2,
            {
                scale: 0.5,
                alpha: 0
            },
            "-=2"
        )
        .to(container, 1, {
            alpha: 0
        })
        .set(container, {
            display: "none"
        })
        .set(radial, {
            zIndex: "-1"
        })
        // .set(document.body, {
        //     overflow: 'auto'
        // })
        .set(radialSvg, {
            className: "+=rotate-anim"
        })
        .call(pagesManager.start)
        .from(
            pageElems,
            1,
            {
                alpha: 0,
                y: "20px"
            },
            "+=.2"
        );

    introTL.play();

    //Pages manager
};
