// Import Highway
import Highway from "@dogstudio/highway";
import initSubCategory from "../pages/subcategory.js";
import init from "../pages/subservice.js";
import scroll from "../modules/utils";
import manageRadial from "../components/radial.js";
import { Snif } from "../helpers/snif";

class SubserviceRenderer extends Highway.Renderer {
    // Hooks/methods
    onEnter() {
        //console.log(this.properties.slug, 'SUBSERVICE');

        /* function for render color */
        let categoryTag = this.properties.view.getAttribute("data-category");
        manageRadial.setRadialByColor("subservice", categoryTag);
        //console.log('categoryTag', categoryTag);
        /* END */

        if (!Snif.isMobile) {
            TweenLite.set(".-toggleElement", {
                className: "+=c-HeightZero"
            });
        }

        initSubCategory.manageSubCategoryMenu();
        init.accordionSubService();

        scroll.ElementsInDOMIntersectionObserver();

        window.addEventListener("scroll", function() {
            scroll.detectElementsInDOM();
        });
        //window.addEventListener("resize", init.setHeightElemContent);
        // window.scrollTo(0, 0);
        this.scrollTop = 0;
    }
    onLeave() {}
    onEnterCompleted() {
        scroll.detectElementsInDOM();
        //console.log('onEnterCompleted', this, window.scrollY);
    }
    onLeaveCompleted() {
        //console.log('onLeaveCompletedx', window.scrollY);
    }
}

// Don`t forget to export your renderer
export default SubserviceRenderer;
