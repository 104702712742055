import Highway from "@dogstudio/highway";

// GSAP Library
import Tween from "gsap";

import { closeMenuTL } from "../components/hamburgerMenu.js";

const fadeTl = new TimelineMax({});
const introLayer = document.getElementsByClassName("c-introLayer")[0];

// Fade
class Fade extends Highway.Transition {
    in({ from, to, trigger, done }) {
        // Reset Scroll
        window.scrollTo(0, 0);

        // Remove Old View
        from.remove();

        // Animation
        Tween.fromTo(
            to,
            1,
            { opacity: 0, y: -10 },
            {
                opacity: 1,
                delay: 0.5,
                y: 0,
                onComplete: done
            }
        );
        to.classList.remove("go-outside");
    }

    out({ from, trigger, done }) {
        closeMenuTL();

        // Animation
        Tween.fromTo(
            from,
            0.5,
            { opacity: 1, y: 0 },
            {
                opacity: 0,
                y: -10,
                onComplete: done
            }
        );
        from.classList.add("go-outside");
    }
}

export default Fade;
