import { Snif } from "../helpers/snif";

const mapStyle = [
    {
        elementType: "geometry",
        stylers: [
            {
                color: "#ebe3cd"
            }
        ]
    },
    {
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#523735"
            }
        ]
    },
    {
        elementType: "labels.text.stroke",
        stylers: [
            {
                color: "#f5f1e6"
            }
        ]
    },
    {
        featureType: "administrative",
        elementType: "geometry.stroke",
        stylers: [
            {
                color: "#ecd2bb"
            }
        ]
    },
    {
        featureType: "administrative.land_parcel",
        elementType: "labels",
        stylers: [
            {
                visibility: "off"
            }
        ]
    },
    {
        featureType: "administrative.land_parcel",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#8f8a85"
            }
        ]
    },
    {
        featureType: "landscape.natural",
        elementType: "geometry",
        stylers: [
            {
                color: "#ecd2bb"
            }
        ]
    },
    {
        featureType: "poi",
        elementType: "geometry",
        stylers: [
            {
                color: "#dfd2ae"
            }
        ]
    },
    {
        featureType: "poi",
        elementType: "labels.text",
        stylers: [
            {
                visibility: "off"
            }
        ]
    },
    {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#93817c"
            }
        ]
    },
    {
        featureType: "poi.business",
        stylers: [
            {
                visibility: "off"
            }
        ]
    },
    {
        featureType: "poi.park",
        elementType: "geometry.fill",
        stylers: [
            {
                color: "#9bb061"
            },
            {
                saturation: -40
            },
            {
                lightness: 5
            }
        ]
    },
    {
        featureType: "poi.park",
        elementType: "labels.text",
        stylers: [
            {
                visibility: "off"
            }
        ]
    },
    {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#447530"
            }
        ]
    },
    {
        featureType: "road",
        elementType: "geometry",
        stylers: [
            {
                color: "#f5f1e6"
            }
        ]
    },
    {
        featureType: "road.arterial",
        elementType: "geometry",
        stylers: [
            {
                color: "#fdfcf8"
            }
        ]
    },
    {
        featureType: "road.arterial",
        elementType: "labels",
        stylers: [
            {
                visibility: "off"
            }
        ]
    },
    {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [
            {
                color: "#a29b94"
            }
        ]
    },
    {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [
            {
                color: "#a79e96"
            }
        ]
    },
    {
        featureType: "road.highway",
        elementType: "labels",
        stylers: [
            {
                visibility: "off"
            }
        ]
    },
    {
        featureType: "road.highway.controlled_access",
        elementType: "geometry",
        stylers: [
            {
                color: "#a9a5a1"
            }
        ]
    },
    {
        featureType: "road.highway.controlled_access",
        elementType: "geometry.stroke",
        stylers: [
            {
                color: "#c2b8ab"
            }
        ]
    },
    {
        featureType: "road.local",
        stylers: [
            {
                visibility: "off"
            }
        ]
    },
    {
        featureType: "road.local",
        elementType: "labels",
        stylers: [
            {
                visibility: "off"
            }
        ]
    },
    {
        featureType: "road.local",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#806b63"
            }
        ]
    },
    {
        featureType: "transit.line",
        elementType: "geometry",
        stylers: [
            {
                color: "#dfd2ae"
            }
        ]
    },
    {
        featureType: "transit.line",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#8f7d77"
            }
        ]
    },
    {
        featureType: "transit.line",
        elementType: "labels.text.stroke",
        stylers: [
            {
                color: "#ebe3cd"
            }
        ]
    },
    {
        featureType: "transit.station",
        elementType: "geometry",
        stylers: [
            {
                color: "#dfd2ae"
            }
        ]
    },
    {
        featureType: "water",
        elementType: "geometry.fill",
        stylers: [
            {
                color: "#007799"
            }
        ]
    },
    {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#92998d"
            }
        ]
    }
];

const obj = [
    {
        city: "Padova",
        info: "SEDE OPERATIVA E LEGALE",
        lat: 45.409274,
        lng: 11.914544,
        address: "Via Giovanni Savelli, 128 (PD)",
        cap: "35129",
        phone: "+39 049 9869596",
        email: "info@365gradi.com"
    },
    {
        city: "Udine",
        info: "SEDE OPERATIVA",
        lat: 46.061036,
        lng: 13.234292,
        address: "Via Ginnasio Vecchio, 3 (UD)",
        cap: "33100",
        phone: "+39 049 9869596",
        email: "info@365gradi.com"
    }
];
const mid_lat = (obj[0].lat + obj[1].lat) / 2;
const mid_lon = (obj[0].lng + obj[1].lng) / 2;
const pos = {
    lat: mid_lat,
    lng: mid_lon
};
// udine 46.061036, 13.234292
// padova 45.409274, 11.914544
let markers = [];
let infoWindow;
let locationSelect;

export const setMap = () => {
    // The map, centered at Uluru
    const map = new google.maps.Map(document.getElementById("map"), {
        zoom: Snif.isMobile ? 6 : 8,
        center: pos,
        styles: mapStyle,
        disableDefaultUI: true
    });
    // The marker, positioned at Uluru
    //const marker = new google.maps.Marker({ position: pos, map: map });

    infoWindow = new google.maps.InfoWindow();
    setDealers(map);
};

const setDealers = map => {
    let marker;
    let count = 0;
    const data = obj;
    const infowindow = new google.maps.InfoWindow({
        maxWidth: 300,
        backgroundColor: "#faf0e7"
    });

    Object.keys(data).forEach(function(item) {
        count++;
        const coordsLat = data[item].lat;
        const coordsLong = data[item].lng;
        const latLng = new google.maps.LatLng(coordsLat, coordsLong);
        const itemLabel = count;

        let contentString =
            '<div class="c-infowindow">' +
            '<span class="c-infowindow__title">' +
            data[item].city +
            "</span>" +
            '<span class="c-infowindow__subTitle">' +
            data[item].info +
            "</span>" +
            '<span class="c-infowindow__divider"></span>' +
            '<div class="c-infowindow__address">' +
            "<span>" +
            data[item].address +
            "</span>" +
            "<span>" +
            data[item].cap +
            "</span>" +
            "</div>" +
            '<div class="c-infowindow__contacts">' +
            '<p><span>Tel: <a href="tel:' +
            data[item].phone +
            '">' +
            data[item].phone +
            "</a></span></p>" +
            '<p><span>E-mail: <a href="mailto:' +
            data[item].email +
            '">' +
            data[item].email +
            "</a></span></p>" +
            "</div>" +
            "</div>";

        marker = new google.maps.Marker({
            position: latLng,
            map: map,
            icon: {
                url:
                    "data:image/svg+xml,%3Csvg version='1.1' id='Livello_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 88 118.6' style='enable-background:new 0 0 88 118.6;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23007094;%7D%0A%3C/style%3E%3Cpath class='st0' d='M87.2,65.8c-0.4-0.2-0.8-0.3-1.3-0.5c-2.7-0.9-5.4-2-8.1-3.1c-1.6-0.7-3.3-0.9-4.7-1.9 c-0.1-0.1-0.2-0.1-0.3-0.1c-1,0.1-3.1,1.2-3.5,2.4c-0.2,0.6-0.4,1.2-0.6,1.8c-1.1,3.7-1.7,7.5-3.1,11.1c-0.3,0.8-0.5,1.7-0.5,2.6 c0,0.5,0.2,0.7,0.8,0.7c0.8-0.2,1.5-0.5,2.1-1c0.6-0.4,1-1.1,1-1.8c0-0.9,0.2-1.7,0.5-2.5c0.8-2.1,1.5-4.3,1.9-6.5 c0-0.2,0-0.4,0.2-0.5c0.2,0,0.3,0.2,0.4,0.4c3.1,5,4.4,10.8,3.8,16.6c-0.3,3.5-1.2,6.9-2.5,10.1c-1.9,4.5-4.6,8.5-8,12 c-5.5,5.6-12.8,8.9-20.6,9.5c-2.5,0.2-5.1,0.1-7.6-0.2c-3.5-0.5-6.8-1.4-10-2.7c-4.6-1.9-8.9-4.7-12.5-8.2 c-3.7-3.5-6.5-7.7-8.5-12.4C4.9,88.2,4.1,84.6,4,81c-0.1-1.7,0-3.4,0.2-5.1c0.4-3.2,1.3-6.3,2.6-9.2c1.7-3.7,4.1-7.1,6.9-10 c2.5-2.5,5.3-4.6,8.3-6.3c8.1,16.5,22.1,32.2,22.9,33c1.1,1.2,3.1,1.2,4.1,0c1.2-1.2,28.6-32,28.6-52.9C77.6,13.5,63.9-0.2,47-0.2 S16.4,13.6,16.4,30.4c0,5.3,1.8,11.3,4.5,17.3c-0.6,0.3-1.2,0.6-1.7,1C13.6,52,8.9,56.6,5.3,62c-2.6,4.1-4.3,8.8-5,13.6 c0,0.9-0.2,1.8-0.4,2.6l0,0.5c0.1,0.9,0.2,1.8,0.1,2.6c0,0.2,0.1,0.5-0.1,0.7v0.5c0.2,0.1,0.1,0.2,0.1,0.3c0.3,4.5,1.5,9,3.4,13.1 c2,4.4,4.8,8.4,8.3,11.7c4.3,4.1,9.6,7.1,15.3,8.8c3.4,1,6.8,1.6,10.3,1.8c2.9,0.2,5.8,0.1,8.7-0.3c2.6-0.3,5.1-0.9,7.6-1.8 c4.9-1.5,9.4-4.1,13.3-7.6c4.8-4.5,8.5-10,10.8-16.2c1.3-3.3,2.1-6.8,2.4-10.3c0.3-3.5,0-7.1-0.9-10.5c-0.4-1.6-1-3.1-1.7-4.6 c-0.1-0.1-0.2-0.3,0-0.5c0.1-0.1,0.3,0,0.4,0.1c2.1,0.8,4.2,1.6,6.3,2.4c1.5,0.6,3.3-0.7,3.7-1.9c0-0.1,0-0.1,0.1-0.2 C88.1,66.5,87.8,66,87.2,65.8z M47,21.1c5.2,0,9.3,4.1,9.3,9.3s-4.3,9.3-9.3,9.3c-5.2,0-9.3-4.1-9.3-9.3S41.8,21.1,47,21.1z'/%3E%3C/svg%3E",
                scaledSize: new google.maps.Size(60, 75)
            }
        });

        google.maps.event.addListener(marker, "click", function(e) {
            infowindow.setContent(contentString);
            infowindow.open(map, this);
        });
    });
};
