// Import Highway
import Highway from '@dogstudio/highway';
import manageRadial from '../components/radial.js';
import scroll from '../modules/utils';
import initemployee from '../pages/team';
import { carousel } from '../pages/team';


class TeamRenderer extends Highway.Renderer {
    // Hooks/methods
    onEnter() {
        //console.log(this.properties.slug, 'Team');
        manageRadial.radialGradient('casestudies', 'initial');

        scroll.ElementsInDOMIntersectionObserver();
        scroll.detectElementsInDOM();
        window.addEventListener('scroll', scroll.detectElementsInDOM);

        document.getElementsByClassName('l-page')[0].classList.add('l-page--team');

    }
    onLeave() { }
    onEnterCompleted() {
        scroll.employeeInteraction();
        initemployee.manageHover();

        if (!document.getElementsByClassName('p-employee__cover')[0]) return;
        const coverImgs = Array.from(document.getElementsByClassName('p-employee__cover'));
        coverImgs.forEach(carousel);
        initemployee.goCarousel();
    }
    onLeaveCompleted() {
        document.getElementsByClassName('l-page')[0].classList.remove('l-page--team');
    }
}

// Don`t forget to export your renderer
export default TeamRenderer;