import { TimelineMax, TweenMax } from "gsap";

const menuAsideContainer = document.getElementsByClassName("c-menuAside")[0];
const manuAsideLiContainer = menuAsideContainer.getElementsByClassName(
    "c-menuAside__list"
)[0];
const manuAsideLi = menuAsideContainer.getElementsByClassName(
    "c-menuAside__li"
);
const manuAsideLiExtra = menuAsideContainer.getElementsByClassName(
    "c-menuAside__liExtra"
);

const menuasideSwitch = () => {
    for (let index = 0; index < manuAsideLi.length; index++) {
        const menuElementLi = manuAsideLi[index];
        menuElementLi.addEventListener("click", function(event) {
            $(".c-menuAside__li").removeClass("c-menuAside__li--active");
            $(".c-menuAside__liExtra").removeClass("c-menuAside__li--active");
            this.classList.add("c-menuAside__li--active");
            ////console.log('menuasideSwitch', this, index);
            //this.classList.add('c-menuAside__li--active');
        });
    }
};
export const manageMenuWithColor = (command, color) => {
    let alreadyActive = document.getElementsByClassName(
        "c-menuAside__li--active"
    )[0]
        ? true
        : false;
    let activeLink;
    let LinkExtra;
    switch (command) {
        case "playtl":
            if (
                alreadyActive &&
                document
                    .getElementsByClassName("c-menuAside__li--active")[0]
                    .classList.contains("c-menuAside__li--" + color)
            )
                return;
            activeLink = document.getElementsByClassName(
                "c-menuAside__li--" + color
            )[0];
            LinkExtra = document.getElementsByClassName(
                "c-menuAside__liExtra--" + color
            )[0];
            menuAsideContainer.classList.add("c-menuAside--colorSelected");
            manuAsideLiContainer.classList.add("c-menuAside--colorSelected");
            LinkExtra.classList.add("c-menuAside__li--active");
            LinkAnimation(command, activeLink, color);
            ////console.log('manageMenuWithColor command', command)
            break;
        case "subservice":
            if (alreadyActive) return;
            activeLink = document.getElementsByClassName(
                "c-menuAside__li--" + color
            )[0];
            LinkExtra = document.getElementsByClassName(
                "c-menuAside__liExtra--" + color
            )[0];
            menuAsideContainer.classList.add("c-menuAside--colorSelected");
            manuAsideLiContainer.classList.add("c-menuAside--colorSelected");
            LinkExtra.classList.add("c-menuAside__li--active");
            LinkAnimation(command, activeLink, color);
            ////console.log('manageMenuWithColor command', command)
            break;
        case "reversetl":
            activeLink = document.getElementsByClassName("c-menuAside__li");
            LinkExtra = document.getElementsByClassName("c-menuAside__liExtra");
            menuAsideContainer.classList.remove("c-menuAside--colorSelected");
            manuAsideLiContainer.classList.remove("c-menuAside--colorSelected");
            LinkAnimation(command, activeLink, color);
            ////console.log('manageMenuWithColor command', command)
            break;
        case "casestudies":
            activeLink = document.getElementsByClassName("c-menuAside__li");
            LinkExtra = document.getElementsByClassName("c-menuAside__liExtra");
            menuAsideContainer.classList.remove("c-menuAside--colorSelected");
            manuAsideLiContainer.classList.remove("c-menuAside--colorSelected");
            LinkAnimation(command, activeLink, color);
            ////console.log('manageMenuWithColor command', command)
            break;
    }
};

const LinkAnimation = (command, activeLi, value) => {
    let menuIsClose = false;

    const activeLinkExtra =
        value !== "active"
            ? document.getElementsByClassName(
                  "c-menuAside__liExtra--" + value
              )[0]
            : document.getElementsByClassName("c-menuAside__liExtra");

    const moveLink = new TimelineMax({
        paused: true
    });
    const setLink = new TimelineMax({
        paused: true
    });
    const moveLinkBack = new TimelineMax({
        paused: true
    });
    const hiddenMenu = new TimelineMax({
        paused: true
    });

    switch (command) {
        case "playtl":
            moveLink.play();
            menuIsClose = true;
            ////console.log('LinkAnimation command', command)
            break;
        case "subservice":
            setLink.play();
            menuIsClose = true;
            break;
        case "reversetl":
            moveLinkBack.play();
            menuIsClose = false;
            //console.log('LinkAnimation command', command)
            break;
        case "casestudies":
            hiddenMenu.play();
            menuIsClose = true;
            break;

        default:
            break;
    }

    moveLink
        .set(".c-menuAside", {
            display: "block"
        })
        .set(".c-menuAside__li", {
            alpha: 1,
            x: "0%"
        })
        .set(".c-menuAside__extraLabel", {
            alpha: 1
        })
        .staggerTo(
            ".c-menuAside__li .c-menuAside__link",
            0.8,
            {
                x: "120%",
                alpha: 0,
                ease: Back.easeOut
            },
            0.2
        )
        .to(
            ".c-menuAside__liExtra",
            0.5,
            {
                y: "100%",
                ease: Back.easeOut
            },
            "+=.2"
        )
        .set(".c-menuAside__li", {
            className: "-=c-menuAside__li--active"
        })
        .set(".c-menuAside__liExtra", {
            className: "-=c-menuAside__li--active"
        })
        .set(activeLinkExtra, {
            className: "+=c-menuAside__li--active"
        })
        .set(activeLi, {
            className: "+=c-menuAside__li--active"
        })
        .to(activeLinkExtra, 0.5, {
            y: "0%",
            ease: Back.easeOut
        })
        .call(showHiddenMenuIfMobile, ["hidden"], "+=1"); // show / hidden menu aside for mobile

    setLink
        .set(".c-menuAside", {
            display: "block"
        })
        .set(".c-menuAside__li", {
            alpha: 1,
            x: "0%"
        })
        .set(".c-menuAside__extraLabel", {
            alpha: 1
        })
        .set(
            ".c-menuAside__li .c-menuAside__link",
            {
                x: "120%",
                alpha: 0
            },
            0.2
        )
        .set(
            ".c-menuAside__liExtra",
            {
                y: "100%",
                ease: Back.easeOut
            },
            "+=.2"
        )
        .set(".c-menuAside__li", {
            className: "-=c-menuAside__li--active"
        })
        .set(".c-menuAside__liExtra", {
            className: "-=c-menuAside__li--active"
        })
        .set(activeLinkExtra, {
            className: "+=c-menuAside__li--active"
        })
        .set(activeLi, {
            className: "+=c-menuAside__li--active"
        })
        .to(
            activeLinkExtra,
            0.5,
            {
                y: "0%",
                ease: Back.easeOut
            },
            "+=.5"
        )
        .call(showHiddenMenuIfMobile, ["hidden"], "+=1"); // show / hidden menu aside for mobile

    moveLinkBack
        .set(".c-menuAside", {
            display: "block"
        })
        .call(showHiddenMenuIfMobile, ["show"]) // show / hidden menu aside for mobile
        .set(".c-menuAside__li", {
            alpha: 1,
            x: "0%"
        })
        .set(".c-menuAside__extraLabel", {
            alpha: 1
        })
        .to(
            ".c-menuAside__liExtra",
            0.8,
            {
                y: "100%",
                ease: Back.easeOut
            },
            "+=.2"
        )
        .set(".c-menuAside__li", {
            className: "-=c-menuAside__li--active"
        })
        .set(".c-menuAside__liExtra", {
            className: "-=c-menuAside__li--active"
        })
        .staggerTo(
            ".c-menuAside__li .c-menuAside__link",
            0.8,
            {
                x: "0%",
                alpha: 1,
                ease: Back.easeOut
            },
            0.2
        );

    hiddenMenu
        .call(showHiddenMenuIfMobile, ["hidden"], "+=1") // show / hidden menu aside for mobile
        .staggerTo(
            ".c-menuAside__li .c-menuAside__link",
            0.8,
            {
                x: "120%",
                alpha: 0,
                ease: Back.easeOut
            },
            0.2
        )
        .set(".c-menuAside__li", {
            alpha: 0
        })
        .to(
            ".c-menuAside__extraLabel",
            0.5,
            {
                alpha: 0
            },
            "-=.2"
        )
        .set(".c-menuAside", {
            display: "none"
        });
};

const showHiddenMenuIfMobile = value => {
    const menuAs = document.getElementsByClassName("c-menuAside")[0];
    TweenLite.set(menuAs, {
        className:
            value === "show" ? "-=c-menuAside--hidden" : "+=c-menuAside--hidden"
    });
};

const initMenuAside = {
    menuasideSwitch: menuasideSwitch,
    manageMenuWithColor: manageMenuWithColor
};

export default initMenuAside;
