export const getFooterInfo = () => {

    const myFooter = document.getElementsByClassName('c-footer')[0];
    const btnAction = myFooter.getElementsByClassName('c-footer__action')[0];
    const disclaimer = myFooter.getElementsByClassName('c-footer__disclaim')[0];
    const hiddenInfo = myFooter.getElementsByClassName('c-footer__info-hidden')[0];
    btnAction.addEventListener('click', function (event) {
        event.preventDefault();
        if (hiddenInfo.classList.contains('-show')) {
            btnAction.innerHTML = '+'
            btnAction.classList.toggle('-active');
        } else {
            btnAction.innerHTML = '';
            btnAction.classList.toggle('-active');
        }
        hiddenInfo.classList.toggle('-show');
        myFooter.classList.toggle('-show');
    });
}