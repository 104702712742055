import { Snif } from "../helpers/snif";
// const shouldSmooth = !Snif.isMobile;

const cursorCircle = document.getElementsByClassName("c-magicMove")[0];
const requestAnimationFrame =
    window.requestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.msRequestAnimationFrame;

const cancelAnimationFrame =
    window.cancelAnimationFrame || window.mozCancelAnimationFrame;
const employeePhoto = document.getElementsByClassName("c-employee__photo");

let scrollType;
let smoothScrollbarInstance;
let containerElement;
let containerHeight = 0;
let currentScrollPosition = 0;
let lastScrollPosition = 0;
let ticking = false;
let data = {};
let inviewCache = [];

let $body;
let $scrolltoElements;

function init() {
    $body = document.body;

    scrollType = "native";
    containerElement = document.body;
    window.addEventListener("scroll", onScroll);

    onScroll();
    setScrollData();
    cacheElements();
}

function onResize() {
    cacheElements();
    onScroll();
}

function onScroll() {
    currentScrollPosition = getScrollPosition();

    if (!ticking) {
        window.requestAnimationFrame(function() {
            animate(currentScrollPosition);
            ticking = false;
        });
        ticking = true;
    }
}

function getScrollPosition() {
    if (scrollType === "smooth") {
        return smoothScrollbarInstance.scrollTop;
    } else {
        return (document.scrollingElement || document.documentElement)
            .scrollTop;
    }
}

function animate() {
    if (document.documentElement.offsetHeight <= window.innerHeight + 60)
        return;
    setScrollData();
    //animateInview();
}
let isScrolling;

function setScrollData() {
    data.scrollTop = currentScrollPosition;
    let difference = currentScrollPosition - lastScrollPosition;
    data.direction = difference > 0 ? "down" : "up";
    data.speed = Math.abs(difference);
    lastScrollPosition = currentScrollPosition;

    $body.classList.remove(
        "is-scrolling-down",
        "is-scrolling-up",
        "not-scrolling",
        "not-to-top"
    );

    window.clearTimeout(isScrolling);

    if (data.direction === "down") {
        $body.classList.add("is-scrolling-down");
    } else if (data.direction === "up") {
        $body.classList.add("is-scrolling-up");
    }
    if (data.scrollTop > 100) {
        $body.classList.add("is-scrolled");
    } else {
        $body.classList.remove("is-scrolled");
    }

    isScrolling = setTimeout(function() {
        // Run the callback
        $body.classList.add("not-scrolling");
    }, 450);
}

function cacheElements() {
    // container
    if (scrollType === "smooth") {
        containerHeight = smoothScrollbarInstance.size.container.height;
    } else {
        containerHeight = window.innerHeight;
    }

    // inview items
    inviewCache = [];
    $("[data-inview]:not(.is-in-view)").each((index, element) => {
        const elBounding = element.getBoundingClientRect();
        const item = {
            elementBounding: element.getBoundingClientRect(),
            element: element,
            isVisible: false,
            top: elBounding.top + currentScrollPosition,
            bottom: elBounding.bottom + currentScrollPosition,
            height: elBounding.height
        };
        inviewCache.push(item);
    });
    ////console.log(inviewCache);
}

const isInViewport = function(elem) {
    let bounding = elem.getBoundingClientRect();
    return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <=
            (window.innerWidth || document.documentElement.clientWidth)
    );
};

/* Detect element in viewport */

export const detectElementsInDOM = () => {
    //const dataInview = document.querySelectorAll('[data-inview]:not(.is-in-view)');
    const dataInview = document.querySelectorAll(
        ".-toogleHeightElem:not(.is-in-view)"
    );
    dataInview.forEach(entry => {
        if (isInViewport(entry)) {
            toggleHeight(entry, true);
        }
    });
};

const ElementsInDOMIntersectionObserver = params => {
    const dataInview = document.querySelectorAll(
        "[data-inview]:not(.is-in-view)"
    );
    let offsetViewportHeight = !Snif.isMobile ? window.innerHeight / 3.5 : 50;
    const config = {
        rootMargin: "50px 20px " + -offsetViewportHeight + "px 30px",
        threshold: [0, 0.25, 0.75, 1]
    };

    if (!!window.IntersectionObserver) {
        let observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.intersectionRatio) {
                    entry.target.classList.add("is-in-view");
                }
            });
        }, config);
        dataInview.forEach(inviewElem => {
            observer.observe(inviewElem);
        });
    }
};

export const toggleHeight = (elem, state) => {
    if (!elem || Snif.isMobile) return;
    let toggleElem = elem;

    let toggleElemContent = toggleElem.getElementsByClassName("-toggleElement");
    let toggleElemContentHeight = toggleElemContent[0].children[0].offsetHeight;
    const eleToStagger = toggleElem.getElementsByClassName("cell");

    let tl = new TimelineMax({
        paused: true,
        reversed: true,
        delay: 1
    });

    tl.to(toggleElemContent, 0.5, { className: "-=c-HeightZero" }, "visible")
        //.to(toggleElemContent, .5, { height: toggleElemContentHeight })
        .set(eleToStagger, { className: "+=c-caseStudy--visible" })
        .set(toggleElem, { className: "+=c-caseStudy--visible" }, "-=.3");

    state ? tl.play() : null;
};

export const splitWords = () => {
    var codes = document.querySelectorAll(".-magicText span");

    for (var i = 0; i < codes.length; i++) {
        let currentCode = codes[i].innerHTML.split(/(?=[ .])/); // split by spaces and periods

        for (var c = 0; c < currentCode.length; c++) {
            // surround each item with nowrap span '.no-wrap-hyphen'
            currentCode[c] =
                '<span class="magic-text-inner no-wrap-hyphen">' +
                currentCode[c] +
                "</span>";
            if (currentCode[c].indexOf(".") > -1) {
                // add a zero size space at the start for periods '.wrap-period'
                currentCode[c] =
                    '<span class="wrap-period">' + currentCode[c] + "</span>";
            }
        }

        codes[i].innerHTML = currentCode.join("");
    }
};

/* const magicMovement = (params) => {
    window.onmousemove = function (e) {
        var x = e.pageX,
            y = e.pageY;
        moveDiv.style.top = (y + 20) + 'px';
        moveDiv.style.left = (x + 20) + 'px';
    };
} */
export const animaCursor = e => {
    let x = event.clientX;
    let y = event.clientY;
    let newposX = x - 30;
    let newposY = y - 30;

    TweenLite.to(cursorCircle, 0.3, {
        css: {
            x: newposX,
            y: newposY
        }
    });
};

let render;
// set the starting position of the cursor outside of the screen
let clientX = -100;
let clientY = -100;

const initCursor = status => {
    document.addEventListener("mousemove", e => {
        clientX = e.clientX;
        clientY = e.clientY;
    });

    render = () => {
        if (!status) return;
        cursorCircle.style.transform = `translate(${clientX}px, ${clientY}px)`;

        TweenMax.set(cursorCircle, {
            x: clientX,
            y: clientY
        });
        requestAnimationFrame(render);
    };
    status ? requestAnimationFrame(render) : cancelAnimationFrame(render);
};

let cliX = 0;
let cliY = 0;

export const moveCircle = (target, status) => {
    const circleParent = target.parentElement;

    document.addEventListener("mousemove", e => {
        cliX = e.offsetX / 20;
        cliY = e.offsetY / 20;
    });

    render = () => {
        if (!status) return;
        target.style.transform = `translate(${cliX +
            target.offsetWidth / 10 / 10}px, ${cliY +
            target.offsetHeight / 10 / 10}px)`;

        TweenMax.set(target, {
            x: cliX + target.offsetWidth / 10 / 10,
            y: cliY + target.offsetHeight / 10 / 10
        });
        requestAnimationFrame(render);
    };
    status ? requestAnimationFrame(render) : cancelAnimationFrame(render);
};

const employeeInteraction = () => {
    if (!document.getElementsByClassName("c-employee") || Snif.isMobile) return;

    let overactive = false;
    for (let index = 0; index < employeePhoto.length; index++) {
        const element = employeePhoto[index];

        const circ = element.getElementsByClassName("c-employee__circle")[0];
        const circPath = element.getElementsByClassName(
            "c-employee__circleSvgPath"
        )[0];

        element.addEventListener("mouseover", function() {
            TweenLite.to(circ, 0.6, {
                scale: 1,
                autoAlpha: 1,
                ease: Power1.easeOut
            });
            TweenLite.to(circPath, 1, {
                strokeDashoffset: 0
            });
            overactive = true;
            moveCircle(circ, overactive);
        });
        element.addEventListener("mouseout", function() {
            TweenLite.to(circ, 0.6, {
                scale: 0.9,
                autoAlpha: 0,
                ease: Power1.easeOut
            });
            TweenLite.to(circPath, 0.6, {
                strokeDashoffset: 900
            });
            overactive = false;
            moveCircle(circ, overactive);
        });
    }
};

const standardAccordion = () => {
    if (!document.getElementsByClassName("-accordion")[0]) return;
    let accordon = document.querySelectorAll(".-accordion");
    accordon.forEach(element => {
        let accordionTarget = element.getElementsByClassName(
            "-accordionBtn"
        )[0];
        let collapsible_element = element.getElementsByClassName(
            "-accordionContent"
        )[0]
            ? element.getElementsByClassName("-accordionContent")[0]
            : null;
        let collapsible_element_children = collapsible_element
            ? collapsible_element.children
            : null;
        let tl = new TimelineMax({
            reversed: true,
            paused: true
        });

        if (!collapsible_element) return;
        //tl.set('.-accordion--open .-accordionContent', { className: "+=-accordion--HeightZero" }, 'close');
        tl.from(
            collapsible_element,
            0.5,
            { className: "+=-accordion--HeightZero" },
            "open"
        );
        tl.set(accordionTarget, { className: "+=-accordion--active" });
        tl.set(element, { className: "+=-accordion--open" });
        tl.set(collapsible_element, { className: "+=-accordion--open" });
        tl.staggerFrom(
            collapsible_element_children,
            0.5,
            { autoAlpha: 0, x: "-30%", ease: Back.easeOut },
            0.025,
            "open+=.1"
        );

        accordionTarget.addEventListener("click", () => {
            tl.reversed() ? tl.play() : tl.reverse();
        });
    });
};

// api
function update() {
    // setup
    cacheElements();
    // trigger
    onScroll();
}

const scroll = {
    init: init,
    update: update,
    detectElementsInDOM: detectElementsInDOM,
    ElementsInDOMIntersectionObserver: ElementsInDOMIntersectionObserver,
    initCursor: initCursor,
    employeeInteraction: employeeInteraction,
    standardAccordion: standardAccordion
};

export default scroll;
