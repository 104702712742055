import { TimelineMax } from "gsap";
import { Snif } from "../helpers/snif";
import { manageMenuWithColor } from "./menuaside.js";
import initCookies from "./cookies.js";

const arcContainer = document.getElementsByClassName("c-radial")[0];
const arc = document.getElementById("radial-arc");
const linearGradient = arc.querySelectorAll("stop");
const bodyTag = document.getElementsByTagName("body")[0];

const stopColorDefault = ["#B1B9EB", "#f88a8d", "#d3d941"];
//const stopColorDefault = ['rgba(172, 179, 229, 1)', 'rgba(247, 127, 123, 1)', 'rgba(220, 212, 76, 1)'];
const stopColorNeutral = [
    "rgba(167, 234, 253, .6)",
    "rgba(100, 202, 227, .8)",
    "rgba(0, 161, 195, 1)"
];

// mixed colours
const stopColorHrCommdev = ["#B1B9EB", "#d3d941", "#d3d941"];
const stopColorHrMktgcomm = ["#B1B9EB", "#f88a8d", "#f88a8d"];
const stopColorMktgcommCommdev = ["#d3d941", "#f88a8d", "#f88a8d"];

let radialIsActive = false;
let comingFromManifest = false;
let shade;
let menuShade;
let bodyBg;
let primaryColor;

export const radialGradient = (state, color) => {
    const gradient = new TimelineMax({ paused: true });
    const gradientInitial = new TimelineMax({ paused: true });
    const gradientHidden = new TimelineMax({ paused: true });
    const gradientNeutral = new TimelineMax({ paused: true });
    // color argument for menu
    menuShade =
        color === "hrMktgcommCommdev"
            ? "blue"
            : color === "hrCommdev"
            ? "blue"
            : color === "hrMktgcomm"
            ? "blue"
            : color === "mktgcommCommdev"
            ? "red"
            : color === "blue"
            ? "blue"
            : color === "red"
            ? "red"
            : "yellow";
    // primary color based on argument
    primaryColor =
        color === "hrMktgcommCommdev"
            ? "#B1B9EB"
            : color === "hrCommdev"
            ? "#B1B9EB"
            : color === "hrMktgcomm"
            ? "#B1B9EB"
            : color === "mktgcommCommdev"
            ? "#f88a8d"
            : color === "blue"
            ? "#B1B9EB"
            : color === "red"
            ? "#f88a8d"
            : color === "yellow"
            ? "#d3d941"
            : "#d3d941";
    console.log("primaryColor", primaryColor);

    // set of colors based on argument
    shade =
        color === "hrMktgcommCommdev"
            ? stopColorDefault
            : color === "hrCommdev"
            ? stopColorHrCommdev
            : color === "hrMktgcomm"
            ? stopColorHrMktgcomm
            : color === "mktgcommCommdev"
            ? stopColorMktgcommCommdev
            : color === "blue"
            ? "#B1B9EB"
            : color === "red"
            ? "#f88a8d"
            : color === "yellow"
            ? "#d3d941"
            : "#d3d941";

    bodyBg =
        color === "blue" ? "#D2D6FC" : color === "red" ? "#FFBABA" : "#E3E08E";

    let timelineDirective =
        color === "hrMktgcommCommdev" ||
        color === "hrCommdev" ||
        color === "hrMktgcomm" ||
        color === "mktgcommCommdev"
            ? true
            : false;
    switch (state) {
        case "playtl":
            gradient.play();
            setTimeout(() => {
                radialIsActive = true;
                comingFromManifest = false;
            }, gradient.duration());

            break;
        case "subservice":
            gradient.play();
            setTimeout(() => {
                radialIsActive = true;
                comingFromManifest = false;
            }, gradient.duration());
            break;
        case "reversetl":
            gradientInitial.play();
            setTimeout(() => {
                radialIsActive = false;
                comingFromManifest = false;
            }, gradientInitial.duration());
            break;
        case "casestudies":
            gradientHidden.play();
            setTimeout(() => {
                radialIsActive = false;
                comingFromManifest = false;
            }, gradientHidden.duration());
            break;
        case "manifest":
            gradientNeutral.play();
            setTimeout(() => {
                radialIsActive = false;
                comingFromManifest = true;
            }, gradientNeutral.duration());
            break;

        default:
            gradientInitial.play();
            setTimeout(() => {
                radialIsActive = false;
                comingFromManifest = false;
            }, gradientInitial.duration());
            break;
    }

    console.log(
        timelineDirective ? shade : [primaryColor, primaryColor, primaryColor]
    );

    gradient
        .call(manageMenuWithColor, [state, menuShade]) // set active class to Menu
        .set(arcContainer, {
            y: "-50%"
        })
        .to(arcContainer, 0.5, {
            alpha: 1
        })
        .staggerTo(
            linearGradient,
            1,
            {
                stopColor: primaryColor,
                cycle: {
                    stopColor: timelineDirective
                        ? shade
                        : [primaryColor, primaryColor, primaryColor]
                }
            },
            0.2,
            timelineDirective ? "+=0.2" : "+=0.5"
        )
        .to(
            arcContainer,
            1.5,
            {
                scale: !radialIsActive ? "+=0.2" : null
            },
            "-=1.5"
        )
        .to(
            arcContainer,
            1,
            {
                x:
                    !radialIsActive && !Snif.isMobile
                        ? "-200%"
                        : !radialIsActive
                        ? "-100%"
                        : state === "subservice" &&
                          radialIsActive &&
                          !Snif.isMobile
                        ? "-200%"
                        : radialIsActive && Snif.isMobile
                        ? "-100%"
                        : null
            },
            "-=.5"
        )
        .to(
            bodyTag,
            1.5,
            {
                backgroundColor: state === "playtl" ? bodyBg : "#faf0e7"
            },
            "-=1.5"
        )
        .call(initCookies.init); // cookies managment

    gradientInitial
        .call(manageMenuWithColor, ["reversetl", "active"]) // reset menu
        // .set(arcContainer, {
        //     alpha: !radialIsActive && !comingFromManifest ? 0 : 1,
        // })
        // .set(arcContainer, {
        //     scale: !radialIsActive && !comingFromManifest ? '-=0.2' : null,
        // })
        /*  .to(arcContainer, .5, {
             alpha: !radialIsActive && !comingFromManifest ? 0 : 1,
         }) */
        .to(arcContainer, 0.5, {
            alpha: 1
        })
        .to(arcContainer, 0.6, {
            scale: radialIsActive ? "-=0.2" : "1"
        })
        .to(
            arcContainer,
            1,
            {
                y: "-50%",
                x: "-50%"
            },
            radialIsActive || comingFromManifest ? "-=1" : "-=3"
        )
        .staggerTo(
            linearGradient,
            1,
            {
                stopColor: "#ff9999",
                cycle: {
                    stopColor: stopColorDefault
                }
            },
            0.2,
            "-=.9"
        )
        .to(
            bodyTag,
            0.5,
            {
                backgroundColor: "#faf0e7"
            },
            "-=1"
        )
        .call(initCookies.init); // cookies managment

    gradientHidden
        .call(manageMenuWithColor, ["casestudies", "active"]) // reset menu
        .to(
            arcContainer,
            1,
            {
                scale: 1,
                alpha: 0
            },
            "-=.5"
        )
        .to(
            arcContainer,
            3,
            {
                y: "100%"
            },
            "-=.5"
        )
        .staggerTo(
            linearGradient,
            1,
            {
                stopColor: "#ff9999",
                cycle: {
                    stopColor: stopColorDefault
                }
            },
            0.2,
            "-=3"
        )
        .to(
            bodyTag,
            0.5,
            {
                backgroundColor: "#faf0e7"
            },
            "-=1"
        )
        .call(initCookies.init); // cookies managment

    gradientNeutral
        .call(manageMenuWithColor, ["casestudies", "active"]) // reset menu
        .to(arcContainer, 0.6, {
            x: radialIsActive ? "-50%" : "-50%"
        })
        .to(
            arcContainer,
            0.3,
            {
                alpha: 1
            },
            "-=.5"
        )
        .to(
            arcContainer,
            1,
            {
                y: "-50%"
            },
            "+=.2"
        )
        .to(
            arcContainer,
            1,
            {
                scale: radialIsActive ? "-=0.2" : null
            },
            "-=1"
        )
        .staggerTo(
            linearGradient,
            1,
            {
                stopColor: "#A7EAFD",
                cycle: {
                    stopColor: stopColorNeutral
                }
            },
            0.2,
            "-=.9"
        )

        .to(
            bodyTag,
            0.5,
            {
                backgroundColor: "#faf0e7"
            },
            "-=1"
        )
        .call(initCookies.init); // cookies managment
};

const setRadialOnHover = () => {
    const AsideLiContainer = document.getElementsByClassName(
        "c-menuAside__list"
    )[0];
    const AsideLi = AsideLiContainer.getElementsByClassName("c-menuAside__li");

    //const myColorInterval = TLOnHover(true, getAttrColor);

    for (let index = 0; index < AsideLi.length; index++) {
        const menuElementLi = AsideLi[index];
        const AsideLiLink = menuElementLi.getElementsByTagName("a")[0];
        const AsideLiLinkAll = menuElementLi.getElementsByTagName("a");
        const getAttrColor = menuElementLi.getAttribute("data-category");
        const getAttrLink = menuElementLi.getAttribute("data-link");

        let timeout = null;

        AsideLiLink.addEventListener("mouseenter", function(event) {
            timeout = setTimeout(() => {
                TLOnHover(true, getAttrColor);
            }, 500);
        });
        AsideLiLink.addEventListener("mouseout", function(event) {
            clearTimeout(timeout);
        });
        AsideLiContainer.addEventListener("mouseleave", function(event) {
            if (
                !AsideLiContainer.classList.contains(
                    "c-menuAside--colorSelected"
                )
            ) {
                TLOnHover(false, null);
            }
        });
    }
};

const TLOnHover = (state, colorAttr) => {
    const gradientOnHover = new TimelineMax({});
    let shade =
        colorAttr === "blue"
            ? "#B1B9EB"
            : colorAttr === "red"
            ? "#f88a8d"
            : "#d3d941";

    if (state) {
        // single color
        gradientOnHover.staggerTo(
            linearGradient,
            0.6,
            {
                stopColor: shade
            },
            0.1
        );
    } else {
        gradientOnHover.staggerTo(
            // default colors
            linearGradient,
            0.5,
            {
                stopColor: "#ff9999",
                cycle: {
                    stopColor: stopColorDefault
                }
            },
            0.2
        );
    }
};

const setRadialByColor = (command, params) => {
    const categoryArray = ["hr", "mktgcomm", "commercialdev"];
    let shade;
    let categoryTag = params;

    console.log(command, params);

    shade =
        categoryTag.indexOf("hr") > -1 &&
        categoryTag.indexOf("commercialdev") > -1 &&
        categoryTag.indexOf("mktgcomm") > -1
            ? "hrMktgcommCommdev"
            : categoryTag.indexOf("hr") > -1 &&
              categoryTag.indexOf("commercialdev") > -1
            ? "hrCommdev"
            : categoryTag.indexOf("hr") > -1 &&
              categoryTag.indexOf("mktgcomm") > -1
            ? "hrMktgcomm"
            : categoryTag.indexOf("commercialdev") > -1 &&
              categoryTag.indexOf("mktgcomm") > -1
            ? "mktgcommCommdev"
            : categoryTag === "hr"
            ? "blue"
            : categoryTag === "mktgcomm"
            ? "red"
            : categoryTag === "commercialdev"
            ? "yellow"
            : "yellow";

    console.log(shade);

    manageRadial.radialGradient(command, shade);
};

const manageRadial = {
    radialGradient: radialGradient,
    setRadialOnHover: setRadialOnHover,
    setRadialByColor: setRadialByColor
};

export default manageRadial;
