// Import Highway
import Highway from "@dogstudio/highway";
import initSubCategory from "../pages/subcategory.js";
import manageRadial from "../components/radial.js";
import { splitWords } from "../modules/utils.js";
import scroll from "../modules/utils";

class SubCategoryRenderer extends Highway.Renderer {
    // Hooks/methods
    onEnter() {
        console.log(this.properties.slug, "Sub Category");
        //splitWords();

        /* function for render color */
        let categoryTag = this.properties.slug;
        manageRadial.setRadialByColor("playtl", categoryTag);
        console.log("categoryTag", categoryTag);
        /* END */

        initSubCategory.manageSubCategoryMenu();
        scroll.ElementsInDOMIntersectionObserver();
    }
    onLeave() {}
    onEnterCompleted() {}
    onLeaveCompleted() {}
}

// Don`t forget to export your renderer
export default SubCategoryRenderer;
