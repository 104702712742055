import { TweenLite } from 'gsap';

const dropCookie = true;                      // false disables the Cookie, allowing you to style the banner
const cookieDuration = 14;                    // Number of days before the cookie expires, and the banner reappears
const cookieName = 'complianceCookie';        // Name of our cookie
const cookieValue = 'on';                     // Value of cookie
const cookieLaw = document.getElementById('cookie-law');
const cookieLawBtn = document.getElementById('cookie-law-btn');
const cookieLawTriggeer = document.getElementsByClassName('c-cookiesbanner__trigger');

// Create cookie
function createCookie(name, value, days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        var expires = "; expires=" + date.toGMTString();
    }
    else var expires = "";

    if (dropCookie) {
        document.cookie = name + "=" + value + expires + "; path=/";
    }
}
// check cookie
function checkCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

function eraseCookie(name) {
    createCookie(name, "", -1);
}

function setCookie() {
    createCookie(cookieName, cookieValue, cookieDuration); // Create the cookie
}

function init() {
    if (checkCookie(cookieName) != cookieValue) {
        // show modal
        showCookiesBanner();
    }
    for (let u = 0; u < cookieLawTriggeer.length; u++) {
        cookieLawTriggeer[u].addEventListener('click', function (event) {
            event.preventDefault();
            setCookie();
            removeMe();
        }, false);
    }

}
function showCookiesBanner() {
    cookieLaw.style.display = 'flex';
    TweenLite.set(cookieLaw, {
        delay: .2,
        className: '+=-is-visible'
    });
    //cookieLaw.classList.add('-is-visible');
}
function removeMe() {
    cookieLaw.classList.remove('-is-visible');
    TweenLite.set(cookieLaw, {
        display: 'none',
        delay: .6
    });
    setTimeout(() => {
        cookieLaw.parentNode.removeChild(cookieLaw);
    }, 600);
}

const initCookies = {
    init: init,
    // createCookie: createCookie,
    // readCookie: readCookie
};

export default initCookies;