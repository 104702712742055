import { Snif } from "../helpers/snif";
import { setTimeout } from "timers";

const featureContent = document.getElementsByClassName(
    "p-employee__featureContent"
)[0];
const coverContainer = document.getElementsByClassName(
    "p-employee__featureCoverContainer"
)[0];
//const coverImgs = coverContainer.getElementsByClassName('p-employee__cover');
const coverImgs = Array.from(
    document.getElementsByClassName("p-employee__cover")
);

const durationGlobal = 3;

const tl = new TimelineLite({
    onComplete: function() {
        this.play(durationGlobal);
    }
});

export const eachSlide = () => {
    coverImgs.forEach(carousel);
    goCarousel();
};
export const carousel = (element, index) => {
    const duration = 3;
    const delay = 1;
    let offset = index === 0 ? 0 : "-=" + (duration + 0.5);

    tl.to(
        element,
        duration,
        { opacity: 1, scale: 1.01, repeat: 1, yoyo: true, repeatDelay: delay },
        offset
    );

    if (index === coverImgs.length - 1) {
        tl.to(coverImgs[0], duration, { opacity: 1, scale: 1.01 }, offset);
    }
};

const goCarousel = () => {
    if (!document.getElementsByClassName("p-employee__cover")[0]) return;
    tl.play(durationGlobal);
};

const manageHover = () => {
    if (
        !document.getElementsByClassName("p-employee__featureCoverContainer")[0]
    )
        return;
    const radialLabel = document.getElementsByClassName("p-employee__label");
    const coverContainer = document.getElementsByClassName(
        "p-employee__featureCoverContainer"
    )[0];

    for (let index = 0; index < radialLabel.length; index++) {
        const label = radialLabel[index];
        const labelAttr = label.getAttribute("data-feature");

        label.addEventListener("mouseover", function(event) {
            tl.pause();
            document.getElementById(labelAttr).classList.toggle("-active");
            coverContainer.classList.toggle("-active");
        });
        label.addEventListener("mouseout", function(event) {
            document.getElementById(labelAttr).classList.remove("-active");
            coverContainer.classList.remove("-active");
            tl.resume();
        });
    }
};

const initemployee = {
    goCarousel: goCarousel,
    manageHover: manageHover,
    carousel: carousel
};

export default initemployee;
