import "@babel/polyfill";
import "whatwg-fetch";

import $ from "jquery";
import "what-input";

// Require the polyfill for IntersectionObserver API
require("intersection-observer");

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
window.$ = $;
//require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

import Ui from "./modules/ui";
Ui.init();

import scroll from "./modules/utils";
scroll.init();

// import InView from './modules/inview';
// InView.init();

import initHamburger from "./components/hamburgerMenu.js";
initHamburger.toogleMenu();

/* import initMenuAside from './components/menuaside.js';
initMenuAside.manageMenuWithColor(); */

import { radialGradient } from "./components/radial.js";
window.radialGradient = radialGradient;

import { getFooterInfo } from "./components/footer.js";
getFooterInfo();

import { PlayIntro } from "./components/intro.js";
PlayIntro();

import initCookies from "./components/cookies.js";
//initCookies.init();

import { Snif } from "./helpers/snif.js";
if (Snif.isIE11) {
    document.body.classList.add("is-ie11");
} else {
    document.body.classList.add("is-not-ie11");
}
if (Snif.isEdge) {
    document.body.classList.add("is-edge");
} else {
    document.body.classList.add("is-not-edge");
}

// Funzione chiamata nel render delle pagine
/* import initSubCategory from './pages/subcategory.js';
initSubCategory.manageSubCategoryMenu(); */

//Pages manager
// import PagesManager from "./pages/core";
// const pagesManager = new PagesManager();
// pagesManager.start();

//$(document).foundation();
