import { Snif } from '../helpers/snif';



const setHeightElemContent = () => {
    const ElemContent = document.getElementsByClassName('-toggleElement');
    for (let index = 0; index < ElemContent.length; index++) {
        const element = ElemContent[index];
        let toggleElemContentHeight = element.children[0].offsetHeight;
        TweenLite.to(element, .2, {
            height: toggleElemContentHeight
        });
    }
}




const accordionSubService = () => {
    if (!Snif.isMobile) return;
    let parent = document.querySelectorAll('.-focusAccordion');
    parent.forEach(element => {
        let clickTarget = element;
        let collapsible_element = element.nextElementSibling ? element.nextElementSibling : null;
        let collapsible_element_children = collapsible_element ? collapsible_element.children : null;
        let tl = new TimelineMax({
            reversed: true,
            paused: true
        });

        if (!collapsible_element) return;
        tl.set('.-focusAccordion--open', { height: 0 }, 'close');
        tl.from(collapsible_element, .5, { className: "+=-focusAccordion--HeightZero" }, 'open');
        //tl.from(collapsible_element, .5, { height: 0 }, 'open');
        tl.set(clickTarget, { className: "+=-focusAccordion--active" });
        tl.set(collapsible_element, { className: "+=-focusAccordion--open" });
        tl.staggerFrom(collapsible_element_children, .5, { autoAlpha: 0, x: '-30%', ease: Back.easeOut }, .025, 'open+=.1');

        clickTarget.addEventListener('click', () => {
            tl.reversed() ? tl.play() : tl.reverse();
        })

    })
};

const init = {
    setHeightElemContent: setHeightElemContent,
    accordionSubService: accordionSubService,
};

export default init;