// Import Highway
import Highway from "@dogstudio/highway";
import manageRadial from "../components/radial.js";
import { setMap } from "../components/map.js";
import scroll from "../modules/utils";

class CasestudiesRenderer extends Highway.Renderer {
    // Hooks/methods
    onEnter() {
        manageRadial.radialGradient("casestudies", "initial");

        scroll.ElementsInDOMIntersectionObserver();
        scroll.detectElementsInDOM();
        scroll.standardAccordion();
        window.addEventListener("scroll", scroll.detectElementsInDOM);
        if (this.properties.slug == "contact") {
            setMap();
        }
    }
    onLeave() {}
    onEnterCompleted() {}
    onLeaveCompleted() {}
}

// Don`t forget to export your renderer
export default CasestudiesRenderer;
