// Import Highway
import Highway from "@dogstudio/highway";

import manageRadial from "../components/radial.js";
import initMenuAside from "../components/menuaside.js";
//import { manageMenuWithColor } from '../components/menuaside.js';

class HomeRenderer extends Highway.Renderer {
    // Hooks/methods
    onEnter() {
        manageRadial.setRadialOnHover();
        manageRadial.radialGradient("reversetl", "initial");
    }
    onLeave() {}
    onEnterCompleted() {
        //initMenuAside.manageMenuWithColor('reversetl', 'active');
    }
    onLeaveCompleted() {}
}

// Don`t forget to export your renderer
export default HomeRenderer;
