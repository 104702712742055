// Import Highway
import Highway from "@dogstudio/highway";

// Import Renderers
import HomeRenderer from "../renderers/home";
import SubserviceRenderer from "../renderers/subservice";
import CasestudiesRenderer from "../renderers/casestudies";
import SubCategoryRenderer from "../renderers/subcategory";
import ManifestRenderer from "../renderers/manifest";
import TeamRenderer from "../renderers/team";

// Import Transitions
import Fade from "../transitions/fade";

class PagesManager {
    constructor(params) {

    }

    start() {
        const H = new Highway.Core({
            renderers: {
                announcements: CasestudiesRenderer,
                announcementsWidget: CasestudiesRenderer,
                home: HomeRenderer,
                // Main services pages
                commercialdev: SubCategoryRenderer,
                hr: SubCategoryRenderer,
                mktgcomm: SubCategoryRenderer,
                // Subservice commercialdev
                subservice: SubserviceRenderer,
                // commercialcontest: SubserviceRenderer,
                // commercialstartup: SubserviceRenderer,
                // franchising: SubserviceRenderer,
                // implementationproject: SubserviceRenderer,
                // productlayout: SubserviceRenderer,
                // retailwholesale: SubserviceRenderer,
                // // Subservice hr
                // executivesearch: SubserviceRenderer,
                // formation: SubserviceRenderer,
                // hrcommunication: SubserviceRenderer,
                // hrdevelopment: SubserviceRenderer,
                // outsourcing: SubserviceRenderer,
                // // Subservice hr => children of executivesearch
                // functionalmapping: SubserviceRenderer,
                // headhunting: SubserviceRenderer,
                // // Subservice mktgcomm
                // brandidentity: SubserviceRenderer,
                // eventsconcept: SubserviceRenderer,
                // internalcommunication: SubserviceRenderer,
                // magazinepubblication: SubserviceRenderer,
                // videomaking: SubserviceRenderer,
                // webcontent: SubserviceRenderer,
                // websocialstrategy: SubserviceRenderer,
                // Case Studies
                casestudies: CasestudiesRenderer,
                singlecasestudy: SubserviceRenderer,
                manifest: ManifestRenderer,
                team: TeamRenderer,
                default: CasestudiesRenderer,
                employee: TeamRenderer,
                contact: CasestudiesRenderer
            },
            transitions: {
                default: Fade,
            },
        });
    }
}

export default PagesManager;
