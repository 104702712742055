export const manageSubCategoryMenu = (category) => {
    let parent = document.querySelectorAll('.p-subCategories__liTitle');

    parent.forEach(element => {
        let clickTarget = element;
        let collapsible_element = element.nextElementSibling ? element.nextElementSibling : null;
        let collapsible_element_children = collapsible_element ? collapsible_element.children : null;
        let tl = new TimelineMax({
            reversed: true,
            paused: true
        });

        if (!collapsible_element) return;
        tl.set(clickTarget, { className: "+=p-subCategories__liTitle--open" });
        tl.from(collapsible_element, .5, { className: "+=c-HeightZero" }, 'open');
        tl.staggerFrom(collapsible_element_children, .5, { autoAlpha: 0, x: '-30%', ease: Back.easeOut }, .025, 'open+=.1');

        clickTarget.addEventListener('click', () => {
            tl.reversed() ? tl.play() : tl.reverse();
        })

    })
};



const initSubCategory = {
    manageSubCategoryMenu: manageSubCategoryMenu,
};

export default initSubCategory;