import { Snif } from "../helpers/snif";
let winW;
function init() {
	if (Snif.isTouch) {
		$("body").addClass("is-touch");
	} else {
		$("body").addClass("is-not-touch");
	}
	winW = $(window).width();
}

function onResize() {
	winW = $(window).width();
}

const ui = {
	init: init,
};

export default ui;
